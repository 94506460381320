// store.js
import { Buffer } from 'buffer';
import createAxiosInstance from '../scripts/apiService';
import { getDataTableLeads } from '../scripts/getDataTableLeads';
import tableData from '../scripts/getStatisticsOfTable';
const generateContactDataFromProfile = require('@/scripts/generateContactDataFromProfile');
import { getTranslatedSortedLeads } from '../scripts/leadConverterFunctions';

let apiService = createAxiosInstance();

const state = {
    customers: [],
    offsets: {
        leadsTotal: 0,
        leadsNew: 0,
        interactions: 0,
    },
    tables: [],
    quickSort: '',
    leads: [],
    percentageLoaded: 0,
    drawer: false,
    search: '',
    agencyLogoURL: '',
    leadID: '',
    sidebar: [],
    loadedTableIDs: [],
    percentageLoadedByTable: {},
    currentlyFetching: [],
    allCustomersLoadedFlag: false,
    archivedCustomersLoadedFlag: false,
    activeStatus: [],
    filters: [],
    customerSearch: '',
    customerSort: 'name1',
    customerPage: 1,
    customerLength: 0,
    stats: null,
};
const mutations = {
    UPDATE_STAT_NEW_CUSTOMER(state) {
        if (!state.stats) return;
        state.stats.amountCustomers++;
        state.stats.amountCustomersLastThirtyDays++;
    },
    UPDATE_STAT_DELETE_CUSTOMER() {
        this.commit('customer/SET_STATS', null);
        this.dispatch('customer/getOrFetchStats');
    },
    UPDATE_STAT_NEW_LEAD(state) {
        if (!state.stats) return;
        state.stats.amountLeads++;
        state.stats.amountLeadsLastThirtyDays++;
    },
    UPDATE_STAT_DELETE_LEAD() {
        this.commit('customer/SET_STATS', null);
        this.dispatch('customer/getOrFetchStats');
    },
    UPDATE_STAT_NEW_TABLE(state) {
        if (!state.stats) return;
        state.stats.amountTables++;
        state.stats.amountTablesLastThirtyDays++;
    },
    UPDATE_STAT_DELETE_TABLE() {
        this.commit('customer/SET_STATS', null);
        this.dispatch('customer/getOrFetchStats');
    },
    SET_CUSTOMER_SEARCH(state, customerSearch) {
        state.customerSearch = customerSearch;
        state.allCustomersLoadedFlag = false;
    },
    SET_CUSTOMER_SORT(state, customerSort) {
        state.customerSort = customerSort;
        state.allCustomersLoadedFlag = false;
    },
    SET_CUSTOMER_PAGE(state, customerPage) {
        state.customerPage = customerPage;
        state.allCustomersLoadedFlag = false;
    },
    SET_CUSTOMER_LENGTH(state, customerLength) {
        state.customerLength = customerLength;
    },
    SET_QUICK_SORT(state, quickSort) {
        state.quickSort = quickSort;
    },
    SET_STATS(state, stats) {
        state.stats = stats;
    },

    SET_FILTERS(state, filters) {
        state.filters = filters;
    },

    SET_PERCENTAGE_LOADED_BY_TABLE(state, { tableID, percentageLoaded }) {
        state.percentageLoadedByTable[tableID] = percentageLoaded;
    },

    ADD_LOADED_TABLE_LEADS(state, tableID) {
        state.loadedTableIDs.push(tableID);
    },
    REMOVE_LOADED_TABLE_LEADS(state, tableID) {
        state.loadedTableIDs = state.loadedTableIDs.filter((id) => id !== tableID);
    },
    LOGIN(state) {
        apiService = createAxiosInstance();
    },

    SET_ACTIVE_STATUS(state, activeStatus) {
        state.activeStatus = activeStatus;
    },

    DELETE_TABLE(state, tableID) {
        state.tables = state.tables.filter((t) => t._id !== tableID);
    },

    SET_SIDEBAR(state, sidebar) {
        state.sidebar = sidebar;
    },
    SET_LEAD_ID(state, leadID) {
        state.leadID = leadID;
    },
    SET_DRAWER(state, drawer) {
        state.drawer = drawer;
    },
    TOGGLE_DRAWER(state) {
        state.drawer = !state.drawer;
    },
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
    APPEND_CUSTOMERS(state, customers) {
        state.customers = [...state.customers, ...customers];
    },
    SET_TABLES(state, tables) {
        //remove possible duplicates from state.tables and replace with incoming tables
        const incomingIDs = tables.map((t) => t._id);
        const filteredTables = state.tables.filter((t) => !incomingIDs.includes(t._id));
        state.tables = [...filteredTables, ...tables];
    },
    SET_LEADS(state, leads) {
        state.leads = leads;
    },
    ADD_LEAD_TO_TABLE(state, lead) {
        const table = state.tables.find((t) => t._id === lead.ownerTable);
        if (!table) {
            return;
        }
        table.leads.push(lead._id);
        table.totalNewLeads++;
        table.stati.push({
            status: 'Unbearbeitet',
            _id: lead._id,
            createdAt: lead.createdAt,
            lastChange: lead.history[lead.history.length - 1].date,
        });
        const customer = state.customers.find((c) => c._id === lead.ownerCustomer);
        if (!customer) {
            return;
        }
        customer.totalNewLeads++;
        customer.totalLeads++;
        state.offsets.leadsNew++;
        state.offsets.leadsTotal++;
    },

    REMOVE_LEAD_FROM_TABLE(state, data) {
        const table = state.tables.find((t) => t._id === data.tableID);
        if (!table) {
            return;
        }
        table.leads = table.leads.filter((l) => l !== data.leadID);
        table.stati = table.stati.filter((s) => s._id !== data.leadID);
        state.offsets.leadsTotal--;
    },

    ADD_LEAD(state, lead) {
        state.leads = [...state.leads, lead];
        const table = state.tables.find((t) => t._id === lead.ownerTable);
        if (table) {
            table.leads.push(lead._id);
            table.totalNewLeads++;
            table.stati.push({
                status: 'Unbearbeitet',
                _id: lead._id,
            });
        }
    },

    DELETE_LEAD(state, leadID) {
        //find lead in table and remove it

        const table = state.tables.find((t) => t.leads.includes(leadID));
        if (table) {
            table.leads = table.leads.filter((l) => l !== leadID);
            table.stati = table.stati.filter((s) => s._id !== leadID);
        }
        state.leads = state.leads.filter((lead) => lead._id !== leadID);
    },

    UPDATE_LEAD(state, lead) {
        state.leads = state.leads.filter((ex) => ex._id !== lead._id);
        state.leads = [...state.leads, lead];
        const table = state.tables.find((t) => t._id === lead.ownerTable);
        if (table) {
            table.stati = table.stati.filter((s) => s._id !== lead._id);
            table.stati.push({
                status: lead.status,
                lastChange: lead.history[lead.history.length - 1].date,
                createdAt: lead.createdAt,
                _id: lead._id,
            });
        }
        state.offsets.interactions++;
    },

    DELETE_CUSTOMER(state, customerID) {
        state.customers = state.customers.filter((customer) => customer._id !== customerID);
        //remove all tables of customer
        state.tables = state.tables.filter((table) => table.ownerCustomer !== customerID);
        //remove all leads of customer
        state.leads = state.leads.filter((lead) => lead.ownerCustomer !== customerID);
    },

    UPDATE_CUSTOMER(state, customer) {
        // state.customers = state.customers.filter((ex) => ex._id !== customer._id);
        const indexOfFound = state.customers.findIndex((c) => c._id === customer._id);
        if (indexOfFound === -1) {
            state.customers = [...state.customers, customer];
            return;
        }
        state.customers = [
            ...state.customers.slice(0, indexOfFound),
            customer,
            ...state.customers.slice(indexOfFound + 1),
        ];
        // state.customers = [...state.customers, customer];
    },

    SET_PERCENTAGE_LOADED(state, percentageLoaded) {
        state.percentageLoaded = percentageLoaded;
    },
    SET_AGENCY_LOGO_URL(state, logoURL) {
        state.agencyLogoURL = logoURL;
    },
    SET_SEARCH(state, search) {
        state.search = search;
    },
    SET_ALL_CUSTOMERS_LOADED_FLAG(state, allCustomersLoadedFlag) {
        state.allCustomersLoadedFlag = allCustomersLoadedFlag;
    },
    SET_ARCHIVED_CUSTOMERS_LOADED_FLAG(state, archivedCustomersLoadedFlag) {
        state.archivedCustomersLoadedFlag = archivedCustomersLoadedFlag;
    },

    SET_CURRENTLY_FETCHING(state, currentlyFetching) {
        state.currentlyFetching = currentlyFetching;
    },
    UPDATE_DESCRIPTION_OF_LEAD(state, { leadID, description }) {
        const lead = state.leads.find((lead) => lead._id === leadID);
        lead.description = description;
    },
};
const actions = {
    async getOrFetchTable({ state, commit }, id) {
        const table = state.tables.find((t) => t._id.toString() === id);
        if (table) {
            return table;
        }
        //if table is currently being fetched, wait for it to finish
        if (state.currentlyFetching.find((fetching) => fetching.type === 'table' && fetching.id === id)) {
            return new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (!state.currentlyFetching.find((fetching) => fetching.type === 'table' && fetching.id === id)) {
                        clearInterval(interval);
                        resolve(state.tables.find((t) => t._id.toString() === id));
                    }
                }, 100);
            });
        }
        //add currently fetching
        commit('SET_CURRENTLY_FETCHING', [...state.currentlyFetching, { type: 'table', id }]);

        const { data: fetchedTable } = await apiService.get(`/table/${id}`, { timeout: 30000 });

        commit('SET_TABLES', [...state.tables, fetchedTable]);
        //remove currently fetching
        commit(
            'SET_CURRENTLY_FETCHING',
            state.currentlyFetching.filter((fetching) => !(fetching.type === 'table' && fetching.id === id))
        );
        return fetchedTable;
    },

    async getOrFetchArchivedCustomers({ state, commit }) {
        if (state.archivedCustomersLoadedFlag) {
            return state.customers;
        }
        const { data: archivedCustomers } = await apiService.get('/customer/all?archived=true', { timeout: 30000 });
        commit('APPEND_CUSTOMERS', archivedCustomers);
        commit('SET_ARCHIVED_CUSTOMERS_LOADED_FLAG', true);
        return state.customers;
    },
    updateCustomerPage({ commit, dispatch }, page) {
        commit('SET_CUSTOMER_PAGE', page);
        commit('SET_ALL_CUSTOMERS_LOADED_FLAG', false);
        commit('SET_CUSTOMERS', []);
        dispatch('getOrFetchCustomers');
    },
    updateCustomerSort({ commit, dispatch }, sort) {
        const oldSort = state.customerSort;
        commit('SET_CUSTOMER_SORT', sort);

        commit('SET_ALL_CUSTOMERS_LOADED_FLAG', false);
        commit('SET_CUSTOMERS', []);
        if (oldSort !== sort) {
            commit('SET_CUSTOMER_PAGE', 1);
        }
        dispatch('getOrFetchCustomers');
    },
    async getOrFetchCustomers({ state, commit }) {
        if (state.allCustomersLoadedFlag) {
            return state.customers;
        }
        const { data: customerData } = await apiService.get(
            `/customer/all?sort=${state.customerSort}&page=${state.customerPage - 1}&search=${state.customerSearch}`,
            { timeout: 30000 }
        );
        const { data: customers, length } = customerData;
        commit('SET_CUSTOMERS', customers);
        commit('SET_CUSTOMER_LENGTH', length);
        setTimeout(() => {
            commit('SET_ALL_CUSTOMERS_LOADED_FLAG', true);
        }, 100);
        return customers;
    },
    async getOrFetchStats({ state, commit }) {
        if (state.stats) {
            return state.stats;
        }

        const { data: stats } = await apiService.get('/customer/all/stats', { timeout: 30000 });
        commit('SET_STATS', stats);
        return stats;
    },
    async getOrFetchCustomer({ state, commit }, id) {
        const customer = state.customers.find((c) => c._id === id);
        if (customer) {
            return customer;
        }
        //check if the request is beeing made by a customerlogin. if so check if the customer is the same as the customerID

        const token = JSON.parse(localStorage.getItem('token'));
        if (token !== '') {
            const base64Payload = token.split('.')[1];
            const payload = Buffer.from(base64Payload, 'base64');
            const decoded = JSON.parse(payload.toString());
            const isAgency = decoded.agency ? true : false;
            if (!isAgency) {
                if (decoded.ownerCustomer !== id) {
                    console.log('CustomerID does not match customerID of token. Aborting.');
                    return;
                }
            }
        }
        //if customer is currently being fetched, wait for it to finish
        if (state.currentlyFetching.find((fetching) => fetching.type === 'customer' && fetching.id === id)) {
            return new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (
                        !state.currentlyFetching.find((fetching) => fetching.type === 'customer' && fetching.id === id)
                    ) {
                        clearInterval(interval);
                        resolve(state.customers.find((c) => c._id === id));
                    }
                }, 100);
            });
        }

        //add customer+id to currently fetching
        commit('SET_CURRENTLY_FETCHING', [...state.currentlyFetching, { type: 'customer', id }]);
        // Fetch customer from API or any data source
        //check if apiService has been created
        if (!apiService) {
            apiService = createAxiosInstance();
        }
        let customerData = null;
        try {
            const { data } = await apiService.get(`/customer/singleAggregated/${id}`, { timeout: 30000 });
            customerData = data;
        } catch (e) {
            console.log('Error fetching customer', e);
        }
        if (!customerData) {
            //customer was deleted or does not exist
            commit(
                'SET_CURRENTLY_FETCHING',
                state.currentlyFetching.filter((fetching) => !(fetching.type === 'customer' && fetching.id === id))
            );
            return null;
        }
        const fetchedCustomer = customerData[0];

        commit('SET_CUSTOMERS', [...state.customers, fetchedCustomer]);
        //remove customer+id from currently fetching
        commit(
            'SET_CURRENTLY_FETCHING',
            state.currentlyFetching.filter((fetching) => !(fetching.type === 'customer' && fetching.id === id))
        );

        return fetchedCustomer;
    },
    async getOrFetchLead({ state, commit }, id) {
        const lead = state.leads.find((l) => l._id === id);
        if (lead) {
            return lead;
        }
        //if lead is currently being fetched, wait for it to finish
        if (state.currentlyFetching.find((fetching) => fetching.type === 'lead' && fetching.id === id)) {
            return new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (!state.currentlyFetching.find((fetching) => fetching.type === 'lead' && fetching.id === id)) {
                        clearInterval(interval);
                        resolve(state.leads.find((l) => l._id === id));
                    }
                }, 100);
            });
        }
        //add lead+id to currently fetching
        commit('SET_CURRENTLY_FETCHING', [...state.currentlyFetching, { type: 'lead', id }]);
        // Fetch lead from API or any data source
        const { data: fetchedLead } = await apiService.get(`/lead/${id}`, { timeout: 30000 });
        commit('SET_LEADS', [...state.leads, fetchedLead]);
        //remove lead+id from currently fetching
        commit(
            'SET_CURRENTLY_FETCHING',
            state.currentlyFetching.filter((fetching) => !(fetching.type === 'lead' && fetching.id === id))
        );

        return fetchedLead;
    },
    async getOrFetchTableList({ state, commit }, customerId) {
        //commented that out as a hot fix for the new sockets implementation
        // Failing case: when the customer is not already visited = no tables in the store
        // if then a new Table is created and notified via socket, the table is added to the store
        // so the length is 1 and when the customer is visited the tables are not fetching again, because a table is in the array
        // so the hot fix was to comment out the persistance of the tables when calling getOrFetchTableList

        // const tableList = state.tables.filter((t) => t.ownerCustomer === customerId);

        // if (tableList.length > 0) {
        //     return tableList;
        // }

        //if table list is currently being fetched, wait for it to finish
        if (state.currentlyFetching.find((fetching) => fetching.type === 'tableList' && fetching.id === customerId)) {
            return new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (
                        !state.currentlyFetching.find(
                            (fetching) => fetching.type === 'tableList' && fetching.id === customerId
                        )
                    ) {
                        clearInterval(interval);
                        resolve(state.tables.filter((t) => t.ownerCustomer === customerId));
                    }
                }, 100);
            });
        }
        //add currently fetching
        commit('SET_CURRENTLY_FETCHING', [...state.currentlyFetching, { type: 'tableList', id: customerId }]);

        // Fetch table list from API or any data source
        const { data: fetchedTableList } = await apiService.get(`/table/all/${customerId}`, { timeout: 30000 });
        commit('SET_TABLES', fetchedTableList);
        //remove currently fetching
        commit(
            'SET_CURRENTLY_FETCHING',
            state.currentlyFetching.filter((fetching) => !(fetching.type === 'tableList' && fetching.id === customerId))
        );
        return fetchedTableList;
    },
    async getOrFetchTableLeads({ state, commit }, tableId) {
        const tableLeads = state.leads.filter((lead) => lead.ownerTable === tableId);

        if (state.loadedTableIDs.includes(tableId)) {
            return tableLeads;
        }

        //if table leads are currently being fetched, wait for it to finish
        if (state.currentlyFetching.find((fetching) => fetching.type === 'tableLeads' && fetching.id === tableId)) {
            return new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (
                        !state.currentlyFetching.find(
                            (fetching) => fetching.type === 'tableLeads' && fetching.id === tableId
                        )
                    ) {
                        clearInterval(interval);
                        resolve(state.leads.filter((lead) => lead.ownerTable === tableId));
                    }
                }, 100);
            });
        }
        //add currently fetching
        commit('SET_CURRENTLY_FETCHING', [...state.currentlyFetching, { type: 'tableLeads', id: tableId }]);
        // Fetch table leads from API or any data source
        const { data: pagecount } = await apiService.get(`/table/leads/pagecount/${tableId}`, { timeout: 30000 });
        let percentageLoaded = 0;

        //remove all other leads that may be in here by socket updates
        commit(
            'SET_LEADS',
            state.leads.filter((lead) => lead.ownerTable !== tableId)
        );
        for (let i = 0; i <= pagecount.pages; i++) {
            const { data } = await apiService.get(`/table/leads/page/${tableId}/${i}`, { timeout: 30000 });
            percentageLoaded = ((i + 1) / pagecount.pages) * 100;
            //wait 1 second
            commit('SET_PERCENTAGE_LOADED_BY_TABLE', { tableID: tableId, percentageLoaded });

            //filter all leads for duplicates
            const filteredLeads = data.filter((lead) => !state.leads.find((l) => l._id === lead._id));
            commit('SET_LEADS', [...state.leads, ...filteredLeads]);
        }

        //remove currently fetching and add table to loaded tables
        commit('ADD_LOADED_TABLE_LEADS', tableId);
        commit(
            'SET_CURRENTLY_FETCHING',
            state.currentlyFetching.filter((fetching) => !(fetching.type === 'tableLeads' && fetching.id === tableId))
        );
        return state.leads.filter((lead) => lead.ownerTable === tableId);
    },
    async createNewLead({ state, commit }, lead) {
        const { data: newLead } = await apiService.post('/lead', lead);
        commit('SET_LEADS', [...state.leads, newLead]);
        return newLead;
    },
    async updateLeads({ state, commit }, leads) {
        // itterate over leads in moveData and get them from the server and call comit('UPDATE_LEAD', lead)
        for (const lead of leads) {
            const { data: updatedLead } = await apiService.get(`/lead/${lead}`);

            commit('DELETE_LEAD', lead);
            commit('SET_LEADS', [...state.leads, updatedLead]);
        }
    },
};
const getters = {
    getCustomerSort: (state) => state.customerSort,
    getCustomerPage: (state) => state.customerPage,
    getOffsets: (state) => state.offsets,
    getSidebar: (state) => state.sidebar,
    getPercentageLoadedByTable: (state) => (tableID) => state.percentageLoadedByTable[tableID],
    getActiveFilters: (state) => state.filters,
    getDrawer: (state) => state.drawer,
    getQuickSort: (state) => state.quickSort,
    getActiveStatus: (state) => state.activeStatus,
    getCustomerLogoURL: (state) => (customerID) => {
        const customer = state.customers.find((c) => c._id === customerID);
        return customer.logoID;
    },
    getCustomerByID: (state) => (id) => state.customers.find((c) => c._id === id),
    getCustomersArray: (state) => state.customers,
    getTableByID: (state) => (id) => state.tables.find((t) => t._id === id),
    getTablesByCustomerID: (state) => (customerId) => state.tables.filter((t) => t.ownerCustomer === customerId),
    getLeadsByTableID: (state) => (tableId) => state.leads.filter((lead) => lead.ownerTable === tableId),
    getLeadsByCustomerID: (state) => (customerId) => {
        state.leads.filter((lead) => lead.ownerCustomer === customerId);
    },
    getLeadByID: (state) => (id) => state.leads.find((lead) => lead._id === id),
    getLeadContactDataByID: (state) => (id) => {
        const lead = state.leads.find((lead) => lead._id === id);
        const table = state.tables.find((t) => t._id === lead.ownerTable);
        return generateContactDataFromProfile(table, lead);
    },
    getTableIsBeeingFetched: (state) => (tableID) => {
        return state.currentlyFetching.find((fetching) => fetching.type === 'tableLeads' && fetching.id === tableID);
    },
    getProfileDataByLeadID: (state) => (id) => {
        const lead = state.leads.find((lead) => lead._id === id);
        const table = state.tables.find((t) => t._id === lead.ownerTable);
        const filtered = getTranslatedSortedLeads(table, [lead])[0];
        const headersForProfile = table.tableAndProfileConfig.filter((e) => e.visibleInProfile);
        let leadData = [];
        for (const header of headersForProfile) {
            leadData.push({
                title: header.name,
                value: filtered[header.name] || '--',
                original: [...header.questions],
            });
        }
        return leadData;
    },

    getSearchCustomerWide: (state) => (customerID) => {
        const currentSearch = state.search.toLowerCase();
        const customer = state.customers.find((c) => c._id === customerID);
        if (customer) {
            const tables = state.tables.filter((t) => t.ownerCustomer === customerID);
            const returnArray = [];

            for (const table of tables) {
                const flatLeads = getDataTableLeads(
                    table.dataTableHeaders,
                    state.leads.filter((lead) => lead.ownerTable === table._id)
                );
                //filter flat leads for search
                const filteredLeads = flatLeads.filter((lead) => {
                    for (const key in lead) {
                        if (lead[key].toLowerCase().includes(currentSearch)) {
                            return true;
                        }
                    }
                    return false;
                });
                returnArray.push({ table, filteredLeads });
            }
            return returnArray;
        }
    },
    getTableDataByTableID: (state) => (tableID) => {
        const data = tableData(
            state.leads.filter((lead) => lead.ownerTable === tableID),
            state.tables.find((t) => t._id === tableID)
        );
        return data;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
