
//export a single function to be used in other files
function tableData(leads, table) {
    //wait 1000ms
    //filter the lead where createdAt is the latest
    const latestLead = leads.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    })[0];
    //filter all leads where history length is more than 1 and status is "Unbearbeitet"
    const leadsUntouched = leads.filter((lead) => {
        return lead.history.length <= 1 && lead.status === "Unbearbeitet";
    }) || [];
    //filter all leads in leadsUnbearbeitet where the createdAt is older than 24 hours
    const leadsOver = leadsUntouched.filter((lead) => {
        const createdAt = new Date(lead.createdAt);
        const now = new Date();
        const diff = now - createdAt;
        const hours = diff / 1000 / 60 / 60;
        return hours > 24;
    }) || [];
    //filter all Leads where where createdAt was in the last 24hours
    const leadsNew = leads.filter((lead) => {
        const createdAt = new Date(lead.createdAt);
        const now = new Date();
        const diff = now - createdAt;
        const hours = diff / 1000 / 60 / 60;
        return hours < 24;
    }) || [];
    //filter the latest change in any history of any lead
    const lastChange = leads.map((lead) => {
        const history = lead.history;
        const lastChange = history.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        })[0];
        return lastChange
    }).sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    })[0] || "--";

    //count for every status which lead has this status

    const statusCount = leads.reduce((acc, lead) => {
        if (acc[lead.status]) {
            acc[lead.status] += 1;
        } else {
            acc[lead.status] = 1;
        }
        return acc
    }, {});
    //create a new object with the status and the count for apexcharts 
    const statusCountArray = Object.keys(statusCount).map((key) => {
        return {
            name: key,
            data: statusCount[key]
        }
    });



    const series = statusCountArray.map((e) => e.data);
    const labels = statusCountArray.map((e) => e.name);

    //generate options for apexcharts donut chart
    const chartOptions = {
        chart: {
            type: "donut",
            height: 70,
            width: 70,
            sparkline: {
                enabled: true,
            },
            animations: {
                enabled: false // Disables animations
            }
        },
        labels: labels,
        series: series,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "70%",
                },
            },
        },
        colors: labels.map((e) => {
            return table.status.find((status) => status.title === e)?.color || "#000000"
        }),


    };
    const retObj = {
        leads: leads?.length || 0,
        latestLead: latestLead?.createdAt || null,
        leadsUntouched: leadsUntouched?.length || 0,
        leadsOver: leadsOver?.length || 0,
        leadsNew: leadsNew?.length || 0,
        lastChange: lastChange?.date || null,
        chartOptions: chartOptions,
    };
    return retObj
}

export default tableData;
