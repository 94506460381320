import axios from 'axios';


const createAxiosInstance = (token) => {
    let instance = null;
    if (!token) {
        token = localStorage.getItem('token');
    }

    if (!token) {

        instance = null; // Reset the instance if there's no token
    } else {

        const data = JSON.parse(token);
        instance = axios.create({
            baseURL: process.env.API_ENDPOINT + "/api/",
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${data}`
            }
        });
    }
    return instance;
};

//export the function to be used in other files
export default createAxiosInstance;