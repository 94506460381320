import WebFont from 'webfontloader';

export function loadFont(fontName) {
    WebFont.load({
        google: {
            families: [fontName + ':300,400,500,600,700,800,900'],
            fontdisplay: 'swap' // Ensure the text is swapped properly
        },
        timeout: 2000 // Optional timeout to prevent long loading times
    });
}