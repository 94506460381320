<template>
    <v-app id="app" v-touch="{
        left: handleSwipeLeft,
        right: handleSwipeRight
    }" :style="getStyles">
        <GlobalSnackbar></GlobalSnackbar>
        <router-view></router-view>
    </v-app>
</template>

<script>
import GlobalSnackbar from './views/GlobalSnackbar.vue';
import { loadFont } from './configs/fontLoader.js'

export default {
    name: "Home",
    data() {
        return {
            bodyFontFamily: "Inter",
        };
    },
    async mounted() {
        // Check for token and track user if present
        const item  = localStorage.getItem('token');
        if (item === null || item === "") {
            return;
        }
        const token = JSON.parse(localStorage.getItem('token'));
        if (token !== "") {
            await this.$store.dispatch("trackUser");
        }
    },
    created() {
        this.setDrawer(false);
    },
    computed: {
        getStyles() {
            const style = this.$store.getters.style();
            const font = style.companyFont || 'Inter';
            loadFont(font.trim());
            return { 'font-family': style.companyFont || 'Inter' };
        }
    },
    methods: {
        setDrawer(bool) {
            this.$store.commit("customer/SET_DRAWER", bool);
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown; // Mobile and tablet devices
        },
        handleSwipeLeft() {
            if (this.isMobile()) {
                this.setDrawer(false);
            }
        },
        handleSwipeRight() {
            if (this.isMobile()) {
                this.setDrawer(true);
            }
        }
    },
    components: { GlobalSnackbar }
};
</script>

<style scoped>
#app {
    background-color: var(--v-background-base);
}

body {
    font-family: var(--font-family-body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}
</style>