const profileFilter = require('./filterProfile');

module.exports = (table, lead) => {
    // Clone the original profile data
    let defaultValue = profileFilter(lead.funnelData.profile);
    if (!table.overrideValues) {
        return { name: defaultValue.name.value, email: defaultValue.email.value, phone: defaultValue.phone.value };
    }

    const { name, email, phone } = table.overrideValues;
    const findKey = function (overrideQuestions, profile) {
        const values = [];
        for (const question of overrideQuestions) {
            const key = Object.keys(profile).find((key) => profile[key].title === question);
            if (key) {
                values.push(profile[key].value);
            }
        }
        return values.length > 0 ? values : ['--'];
    };
    const profile = lead.funnelData.profile;
    if (name.length > 0) {
        defaultValue.name.value = findKey(name, profile).join(', ');
    }

    if (email.length > 0) {
        defaultValue.email.value = findKey(email, profile).join(', ');
    }

    if (phone.length > 0) {
        defaultValue.phone.value = findKey(phone, profile).join(', ');
    }
    return {
        name: defaultValue.name.value,
        email: defaultValue.email.value,
        phone: defaultValue.phone.value,
    };
};
