import store from '../store';
import { updateCustomerSidebar } from '@/components/Sidebar/helpers/updateCustomerSidebar';
import Vue from 'vue';

const processDeleteLead = (foundCustomer, status) => {
    foundCustomer.totalNewLeads--;
    foundCustomer.totalLeads--;
    foundCustomer.leadsCombined--;
    foundCustomer.newLeadsLast30Days--;
    foundCustomer.lastInteraction = new Date().getTime();
    foundCustomer.statusCounts[status]--;
    if (status === 'Unbearbeitet') foundCustomer.unusedLeads--;
};

const processNewLead = (foundCustomer, status = null) => {
    foundCustomer.totalNewLeads++;
    foundCustomer.leadsCombined++;
    foundCustomer.newLeadsLast30Days++;
    foundCustomer.lastInteraction = new Date().getTime();
    foundCustomer.newestLeadDate = new Date().toISOString();
    foundCustomer.statusCounts[status || 'Unbearbeitet']++;
    if (status === 'Unbearbeitet') foundCustomer.unusedLeads++;
};

export function initSocketListeners(socket, store) {
    // when a new lead is created, fetch it and add it to the store
    socket.on('newLeadCreated', async (data) => {
        //const { data: requestData } = await Vue.prototype.$api.get(`/customer/singleAggregated/${data.customerID}`, {
        //    timeout: 30000,
        //});
        //const fetchedCustomer = requestData[0];
        //store.commit('customer/UPDATE_CUSTOMER', fetchedCustomer);
        //if (Object.keys(data).length > 1) {
        //    if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_NEW_LEAD');
        //}

        const foundCustomer = await store.getters['customer/getCustomerByID'](data.customerID);
        if (!foundCustomer) {
            return;
        }

        processNewLead(foundCustomer);

        // foundCustomer.totalNewLeads++;
        // foundCustomer.leadsCombined++;
        // foundCustomer.newLeadsLast30Days++;
        // foundCustomer.lastInteraction = new Date().getTime();
        // foundCustomer.newestLeadDate = new Date().toISOString();
        // foundCustomer.statusCounts['Unbearbeitet']++;
        store.commit('customer/UPDATE_CUSTOMER', foundCustomer);
        if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_NEW_LEAD');

        await store.dispatch('customer/getOrFetchLead', data.leadID);
        const lead = store.getters['customer/getLeadByID'](data.leadID);
        const table = store.getters['customer/getTableByID'](lead.ownerTable);
        store.commit('customer/ADD_LEAD_TO_TABLE', lead);
        updateCustomerSidebar(data.customerID);
    }); // done

    // when a lead is deleted, remove it from the store
    socket.on('LeadDelete', async (data) => {
        // const { data: requestData } = await Vue.prototype.$api.get(`/customer/singleAggregated/${data.customerID}`, {
        //     timeout: 30000,
        // });
        // const fetchedCustomer = requestData[0];
        // store.commit('customer/UPDATE_CUSTOMER', fetchedCustomer);

        const foundCustomer = await store.getters['customer/getCustomerByID'](data.customerID);
        if (!foundCustomer) {
            return;
        }

        processDeleteLead(foundCustomer, data.status);

        store.commit('customer/UPDATE_CUSTOMER', foundCustomer);

        if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_DELETE_LEAD');

        store.commit('customer/DELETE_LEAD', data.leadID);
        store.commit('customer/REMOVE_LEAD_FROM_TABLE', data);
        updateCustomerSidebar(data.customerID);
        store.commit('logSuccess', { msg: `Lead ${data.name} gelöscht` });
    }); // done

    socket.on('deleteMultipleLeads', async (data) => {
        // TODO: update customer data
        for (const customerID of Object.keys(data.affectedLeadsByCustomers)) {
            const c = data.affectedLeadsByCustomers[customerID];

            const foundCustomer = await store.getters['customer/getCustomerByID'](customerID);
            if (!foundCustomer) {
                return;
            }

            for (const status of c) {
                processDeleteLead(foundCustomer, status);
                if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_DELETE_LEAD');
            }

            store.commit('customer/UPDATE_CUSTOMER', foundCustomer);

            // const { data: requestData } = await Vue.prototype.$api.get(`/customer/singleAggregated/${customer}`, {
            //     timeout: 30000,
            // });
            // const fetchedCustomer = requestData[0];
            // store.commit('customer/UPDATE_CUSTOMER', fetchedCustomer);
        }
        // if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_DELETE_LEAD');

        const affectedLeads = data.affectedLeads;
        affectedLeads.forEach((leadID) => {
            store.commit('customer/DELETE_LEAD', leadID);
            store.commit('customer/REMOVE_LEAD_FROM_TABLE', leadID);
        });
        updateCustomerSidebar(data.customerID);
        store.commit('logSuccess', { msg: `${affectedLeads.length} Leads gelöscht` });
    });

    socket.on('updateLead', async (data) => {
        const { oldLead, newLead } = data;
        store.commit('customer/UPDATE_LEAD', newLead);

        if (oldLead.ownerCustomer !== newLead.ownerCustomer) {
            updateCustomerSidebar(oldLead.ownerCustomer);
            updateCustomerSidebar(newLead.ownerCustomer);
            const foundOldCustomer = await store.getters['customer/getCustomerByID'](oldLead.ownerCustomer);
            const foundNewCustomer = await store.getters['customer/getCustomerByID'](newLead.ownerCustomer);
            processDeleteLead(foundOldCustomer, oldLead.status);
            if (
                oldLead.status === 'Unbearbeitet' &&
                oldLead.createdAt > new Date(new Date().setHours(new Date().getHours() - 24))
            ) {
                foundOldCustomer.overdueLeads--;
                foundNewCustomer.overdueLeads++;
            } else if (
                oldLead.status ===
                oldLead.createdAt < new Date(new Date().setHours(new Date().getHours() - 24))
            ) {
                foundOldCustomer.totalNewLeads--;
                foundNewCustomer.totalNewLeads++;
            }

            store.commit('customer/UPDATE_CUSTOMER', foundOldCustomer);

            processNewLead(foundNewCustomer, newLead.status);
            store.commit('customer/UPDATE_CUSTOMER', foundNewCustomer);
        } else if (oldLead.status !== newLead.status) {
            const foundCustomer = await store.getters['customer/getCustomerByID'](oldLead.ownerCustomer);
            if (!foundCustomer) {
                return;
            }

            processDeleteLead(foundCustomer, oldLead.status);
            processNewLead(foundCustomer, newLead.status);

            store.commit('customer/UPDATE_CUSTOMER', foundCustomer);
        }

        // store.commit('logSuccess', { msg: `Lead ${lead.name} aktualisiert` });

        //try {
        //    const { data:customer } = await Vue.prototype.$api.get(`/customer/singleAggregated/${data.ownerCustomer}`, {
        //        timeout: 30000,
        //    });
        //    //commit the updated customer to the store
        //    store.commit('customer/UPDATE_CUSTOMER', customer[0]);
        //} catch (error) {
        //    console.log(error);
        //}
    });

    //#region tables

    // when a table is deleted, remove it from the store and update the stats
    socket.on('tableDeleted', (data) => {
        //filter table from tables in store and then log success

        store.commit('customer/DELETE_TABLE', data);
        store.commit('logSuccess', { msg: `Kampagne gelöscht` });
        if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_DELETE_TABLE');
    }); // done

    // when a table is updated, remove it from the store and add the updated version
    socket.on('updateTable', async (data) => {
        console.log('updateTable');
        store.commit('customer/DELETE_TABLE', data.tableID);
        const newTable = await store.dispatch('customer/getOrFetchTable', data.tableID);
        if (data.customerID) {
            updateCustomerSidebar(data.customerID);
        }

        store.commit('logSuccess', { msg: `Kampagne ${newTable.occupation} aktualisiert` });
    }); // done

    // when a new table is created, add it to the store and update the stats
    socket.on('newTable', async (data) => {
        store.commit('customer/DELETE_TABLE', data);
        const newTable = await store.dispatch('customer/getOrFetchTable', data);
        store.commit('logSuccess', { msg: `Neue Kampagne ${newTable.occupation} erstellt` });
        if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_NEW_TABLE');
    }); // done

    //#endregion tables

    socket.on('leadsMoved', async (data) => {
        store.dispatch('customer/updateLeads', data);
    });

    socket.on('reloadCustomer', async (customerId) => {
        const { data: customer } = await Vue.prototype.$api.get(`/customer/singleAggregated/${customerId}`, {
            timeout: 30000,
        });
        store.commit('customer/UPDATE_CUSTOMER', customer[0]);
    });

    socket.on('debug', (data) => {
        console.log('DEBUG', data);
    });

    // old agency events \/

    socket.on('newLeadAgency', async (data) => {});
    socket.on('updateLeadAgency', async (data) => {});
    socket.on('deleteLeadAgency', async (data) => {});
    socket.on('deleteMultipleLeadsAgency', async (data) => {});
    socket.on('newTableAgency', async (data) => {});
    socket.on('deleteTableAgency', async (data) => {});
}
